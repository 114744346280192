<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile/Withdrawal">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.bankcard") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="Sys_form">
        <AForm
          layout="vertical"
          :model="formModel"
          ref="formRef"
          :rules="ruleRef"
          validateTrigger="blur"
          @submit.prevent="handleConfirm"
          autocomplete="off"
          v-if="alreadyReq"
        >
          <h3>{{ t("common.title.withdrawal") }}</h3>
          <div class="box">
            <ul>
              <li>{{ t("common.title.withdrawal") }}</li>
              <li>
                <AFormItem name="methodType">
                  <a-select :disabled="id != 'noBind'" v-model:value="formModel.methodType" @change="changeTypeEvent">
                    <a-select-option v-for="(item, index) in dataList" :value="item.methodType" :key="index" >{{ getMethodTypeName(item.methodType)  }}</a-select-option>
                  </a-select>
                </AFormItem>

                <!-- <AFormItem name="methodType">
                  <a-select v-model:value="formModel.methodType" @change="changeTypeEvent" :disabled="id != 'noBind'">
                    <a-select-option :value="1" key="1">{{ t("profile.label.methodType_1") }}</a-select-option>
                    <a-select-option :value="2" key="2">{{ t("profile.label.methodType_2") }}</a-select-option>
                    <a-select-option :value="3" key="3">{{ t("profile.label.methodType_3") }}</a-select-option>
                    <a-select-option :value="4" key="4">{{ t("profile.label.methodType_4") }}</a-select-option>
                  </a-select>
                </AFormItem> -->
              </li>
            </ul>
          </div>

          <!-- <h3>{{ t("profile.label.yourId") }}</h3> -->
          <!-- <div class="box">
            <ul>
              <li>{{ t("profile.label.contactMobile") }}</li>
              <li>
                <AFormItem name="mobile">
                  <AInput
                    :maxlength="15"
                    v-model:value="formModel.mobile"
                    :placeholder="t('profile.placeholder.contactPhone')"
                    :readonly="id != 'noBind'"
                  />
                </AFormItem>
              </li>
            </ul>
            <ul>
              <li>{{ t("profile.label.contactEmail") }}</li>
              <li>
                <AFormItem name="email">
                  <AInput
                    v-model:value="formModel.email"
                    :placeholder="t('profile.placeholder.contactEmail')"
                    :readonly="id != 'noBind'"
                  />
                </AFormItem>
              </li>
            </ul>
          </div> -->
          <h3>{{ t("profile.label.yourAccountInfo") }}</h3>
          <div class="box">
            <ul v-if="+formModel.methodType === 4">
              <li>{{ t("profile.label.bank") }}</li>
              <li>
                <AFormItem name="bankName">
                  <a-select
                    v-model:value="formModel.bankName"
                  >
                    <a-select-option
                      v-for="(item, index) in banks"
                      :value="item.bankName"
                      :key="index"
                      >{{ item.bankName }}</a-select-option
                    >
                  </a-select>
                </AFormItem>
              </li>
            </ul>

            <ul v-if="+formModel.methodType === 1">
              <li>{{ t("profile.label.pixType") }}</li>
              <li>
                <AFormItem name="bankName">
                  <a-select
                    v-model:value="formModel.bankName"
                  >
                    <a-select-option
                      v-for="(item) in pixTypes"
                      :value="item"
                      :key="item"
                      >{{ item }}</a-select-option
                    >
                  </a-select>
                </AFormItem>
              </li>
            </ul>


            <ul>
              <li v-if="sysDataDictionaryWbObj.NATION?.dataContent === 'PERU' && locale === 'es'">{{ +formModel.methodType === 1 ? t("common.title.Account"): (+formModel.methodType === 2 || +formModel.methodType === 3? t("common.title.withdrawalAddress"):(+formModel.methodType === 4 ? t("common.title.cardNumber_1"):'')) }}</li>
              <li v-else>{{ +formModel.methodType === 1 ? t("common.title.Account"): (+formModel.methodType === 2 || +formModel.methodType === 3? t("common.title.withdrawalAddress"):(+formModel.methodType === 4 ? t("common.title.cardNumber"):'')) }}</li>

              <li>
                <AFormItem name="acctNo">
                  <span class="t_span">{{((id === 'noBind' && +formModel.methodType === 4 && locale === 'tur')?'TR':'')}}</span>
                  
                  <AInput class="t_padding"
                    v-if="sysDataDictionaryWbObj.NATION?.dataContent === 'PERU' && locale === 'es'"
                    v-model:value="formModel.acctNo"
                    :placeholder="+formModel.methodType === 1 ? t('common.title.Account'): (+formModel.methodType === 2 || +formModel.methodType === 3? t('common.title.withdrawalAddress'):(+formModel.methodType === 4 ? t('common.title.cardNumber2_1'):''))"
                    
                  />
                  <AInput class="t_padding"
                    v-else
                    v-model:value="formModel.acctNo"
                    :placeholder="+formModel.methodType === 1 ? t('common.title.Account'): (+formModel.methodType === 2 || +formModel.methodType === 3? t('common.title.withdrawalAddress'):(+formModel.methodType === 4 ? t('common.title.cardNumber2'):''))"
                    
                  />
                  
                </AFormItem>
              </li>
            </ul>
            <ul v-if="+formModel.methodType === 1 || +formModel.methodType === 4">
              <li>{{ +formModel.methodType === 1 ? t("common.title.Name"): (+formModel.methodType === 2 || +formModel.methodType === 3? '':(+formModel.methodType === 4 ? t("common.title.actualName"):'')) }}</li>
              <li>
                <AFormItem name="acctName">
                  <AInput
                    v-model:value="formModel.acctName"
                    :placeholder="+formModel.methodType === 1 ? t('common.title.Name'): (+formModel.methodType === 2 || +formModel.methodType === 3? '':(+formModel.methodType === 4 ? t('common.title.actualName'):''))"
                    
                  />
                </AFormItem>
              </li>
            </ul>

            <ul v-if="+formModel.methodType === 4 &&  sysDataDictionaryWbObj?.TKCI?.dataOnOff">
              <li>{{sysDataDictionaryWbObj?.TKCI?.dataContent}}:</li>
              <li>
                <AFormItem name="bankCode">
                  <AInput
                    v-model:value="formModel.bankCode"
                    :placeholder="t('profile.placeholder.fundCI')"
                    
                  />
                </AFormItem>
              </li>
            </ul>


          </div>
          <div class="Sys_submit_btn bankcard Sys_submit_btn_d">
            <button type="submit" >
              {{ t("common.button.confirm") }}
            </button>
          </div>
          <!-- <div class="Sys_tips c-red">
            <p class="ptext">{{ t("common.text.bankcard") }}</p>
            <p>{{ t("common.text.bankcard2") }}</p>
            <p>{{ t("common.text.bankcard3") }}</p>
            <p>{{ t("common.text.bankcard4") }}</p>
            <p>{{ t("common.text.bankcard5") }}</p>
            <p>{{ t("common.text.bankcard6") }}</p>
            <p>{{ t("common.text.bankcard7") }}</p>
          </div> -->
        </AForm>
      </div>
    </div>
  </div>
  <Loading :loading="loading" />
</template>

<script>
import {
  ref,
  reactive,
  computed,
  createVNode,
  unref,
  onBeforeMount,
  getCurrentInstance,
  onMounted
} from "vue";
import { message, Modal } from "ant-design-vue";
import { useRouter } from "vue-router";
import Loading from "@/components/Loading.vue";
import { formatMoney } from "@/utils/formatter";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useI18n } from "@/lang";
import { RE_PHONE_NUMBER } from "@/utils/constant";
import api from "@/api";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: { Loading },
  setup() {
    onBeforeMount(async () => {
      let res2 = await api.getAllWithdrawal();
      if (res2) {
        dataList.value = res2.data?.data || [];
      }
      
      let res = JSON.parse(sessionStorage.getItem('userWithdrawal'))
      if (res) {
        alreadyReq.value = true;
        let form = res.find((item) => {
          return ''+item.myBankId === ''+sessionStorage.getItem('userMyBankId')
        });

        if (form) {
          id.value = form.myBankId;
          formModel.value = {
            acctName: form.openAccountName,
            // mobile: form.contactPhone,
            acctNo: form.bankCord,
            // email: form.contactEmail,
            bankName: form.bankName,
            methodType: form.methodType,
            bankCode:form.bankCode
          };
        }else{
          formModel.value = {
            acctName: "",
            // mobile: "",
            acctNo: "",
            bankName: "",
            // email: "",
            methodType: "",
            bankCode:""
          };
        }
      }
      

      let res1 = await api.bankList();
      if (res1?.data?.success) {
        banks.value = res1.data.data;
        console.log(banks.value[5].bankName);
      }
      
    });
    onMounted(async () => {
      
    })
    function getMethodTypeName(type){
      let nameStr = ''
      switch (+type) {
        case 1:
          nameStr = t('profile.label.methodType_1')
          break;
        case 2:
          nameStr = t('profile.label.methodType_2')
          break;
        case 3:
          nameStr = t('profile.label.methodType_3')
          break;
        case 4:
          nameStr = t('profile.label.methodType_4')
          break;
      }
      return nameStr
    }
    let dataList = ref(null);
    const alreadyReq = ref(false);
    const { t,locale } = useI18n();
    let loading = ref(false);
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const id = ref("noBind");
    const banks = ref([]);
    const pixTypes = ref(['CPF', 'CNPJ', 'PHONE', 'EMAIL', 'EVP']);
    const formModel = ref({
      acctName: "",
      // mobile: "",
      acctNo: "",
      bankName: "",
      // email: "",
      methodType: 4
    });
    const back = computed(() => {
      const {
        query: { back },
      } = unref(router.currentRoute);
      return back;
    });
    const ruleRef = reactive({
      acctName: [
        {
          required: true,
          message:  (+formModel.value.methodType === 1 ? t("profile.placeholder.accountName"): (+formModel.value.methodType === 4? (+formModel.value.methodType === 4 ? t('profile.placeholder.realName'):''):'')) //t("profile.placeholder.accountName"),
        },
        {
          min: 3,
          max: 300,
          message:
            t("extra.minLength", { min: 3 }) +
            t("extra.maxLength", { max: 300 }),
          trigger: "blur",
        },
      ],
      acctNo: [
        {
          required: true,
          message: (sysDataDictionaryWbObj.value?.NATION?.dataContent === 'PERU' && locale === 'es')? t("profile.placeholder.accountNo_1") : t("profile.placeholder.accountNo"),
        },
        {
          validator: (rule, value) => {
            if (id.value) {
              return Promise.resolve();
            }
            return /^\d+$/.test(value)
              ? Promise.resolve()
              : Promise.reject(t("profile.hits.validAccountNo"));
          },
        },
      ],
      mobile: [
        {
          required: true,
          message: t("profile.placeholder.contactPhone"),
        },
        {
          validator: (rule, value) => {
            if (id.value != "noBind") {
              return Promise.resolve();
            }
            return RE_PHONE_NUMBER.test(value)
              ? Promise.resolve()
              : Promise.reject(t("profile.placeholder.validPhoneNumber"));
          },
        },
      ],
      email: [
        {
          required: true,
          type: "email",
          message: "invalid email",
        },
      ],
      bankName: [
        {
          required: true,
          message: t("profile.placeholder.bank"),
        },
      ],
      methodType: [
        {
          required: true,
          message: t("profile.placeholder.withdrawalType"),
        },
      ],
    });

    // TODO: fix form width
    const selectWidth = computed(() => {
      return document.body.clientWidth - 150;
    });

    async function handleConfirm() {
      // console.log(formModel.value.methodType,'------fffffffffffff')
      // if(+formModel.value.methodType === 1){
      //   console.log(formModel,'-222222222222222222222222222');
      //   return
      // }
      let validateResult = await proxy.$refs["formRef"]
        .validate()
        .then((e) => {
          return e;
        })
        .catch((e) => {
          return e;
        });
      if (
        !(
          validateResult &&
          validateResult.errorFields &&
          validateResult.errorFields.length === 0
        )
      ) {
        return;
      }
      
      confirmSubmit(formModel);
    }

    function changeTypeEvent(){
      // formModel.value.acctName = '...'
      if(+formModel.value.methodType === 1 || +formModel.value.methodType === 4){
        formModel.value.acctName = ''
      }
      // console.log(''+formModel.value.methodType !=='2' || ''+formModel.value.methodType !=='3')
      // if(''+formModel.value.methodType !=='2' || ''+formModel.value.methodType !=='3'){
      //   formModel.value.acctName = ''
      // }

    }

    function confirmSubmit(form) {
      // console.log((!formModel.value.bankCode || formModel.value.bankCode.length <= 0),'----------------------------------')
      if (sysDataDictionaryWbObj.value?.TKCI?.dataOnOff && (!formModel.value.bankCode || formModel.value.bankCode.length <= 0)) {
        message.info(t('profile.placeholder.fundCI'))
        return;
      }
      Modal.confirm({
        title: t("common.title.prompt"),
        icon: createVNode(ExclamationCircleOutlined),
        width: "80%",
        centered: true,
        className: "sss",
        content: t("profile.hits.bindAccountConfirm"),
        // t('profile.hits.bindAccountConfirm', {
        //   fee: '?',
        // })
        okText: t("common.button.confirm"),
        onOk: async () => {
          await handleSubmit(form);
        },
        onCancel() {},
      });
    }

    async function handleSubmit(form) {
      loading.value = true;
      console.log(form);
      try {
        //调接口
        let formToSend = {};
        if (id.value === "noBind") {
          formToSend = {
            bankCord: (locale.value === 'tur' && +formModel.value.methodType === 4)?'TR'+formModel.value.acctNo:formModel.value.acctNo,
            bankName: formModel.value.bankName,
            // contactEmail: formModel.value.email,
            // contactPhone: formModel.value.mobile,
            openAccountName: (+formModel.value.methodType ===2 || +formModel.value.methodType ===3)?'':formModel.value.acctName,
            accountType: formModel.value.methodType,
            bankCode: formModel.value.bankCode
          };
        } else {
          formToSend = {
            bankCord: formModel.value.acctNo,
            bankName: formModel.value.bankName,
            // contactEmail: formModel.value.email,
            // contactPhone: formModel.value.mobile,
            openAccountName: (+formModel.value.methodType ===2 || +formModel.value.methodType ===3)?'':formModel.value.acctName,
            accountType: formModel.value.methodType,
            id: id.value,
            bankCode: formModel.value.bankCode
          };
        }

        await api.bindBankCard(formToSend);

        if (back?.value) {
          await router.replace("/withdraw");
        } else {
          await router.replace("/profile/Withdrawal");
        }
      } catch (e) {
        message.error(t("common.message.bindAccountFailed"));
        await router.replace("/profile/Withdrawal");
      } finally {
        loading.value = false;
      }
    }

    return {
      t,
      formModel,
      ruleRef,
      handleConfirm,
      loading,
      selectWidth,
      formatMoney,
      alreadyReq,
      id,
      banks,
      pixTypes,
      changeTypeEvent,
      dataList,
      getMethodTypeName,
      locale,
      sysDataDictionaryWbObj
    };
  },
};
</script>

<style lang="scss">
.ant-input {
  border-bottom: none !important;
}

.ant-input:focus {
  border-bottom: none !important;
}

.ant-form-item-children input:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

.Sys_submit_btn.bankcard {
  button {
    &[disabled] {
      background: #a0a0a0;
    }
  }
}
.t_padding{
  padding-left:8px !important;
}
.Sys_tips p{
  line-height: 22px;
  padding-bottom: 10px;
}
.ptext{
  font-weight: bold;
  font-size:16px;
  color:#bb0303;
}
.t_span{
  float: left;
  display: inline-block;
  padding: 15px 0;
  font-size: 12px;
  line-height: 20px;
}
</style>
<style lang="scss" scoped>
.Sys_form ul input{
  float: left;
  display: inline-block;
  width:90%;
}
</style>